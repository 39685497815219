import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = [
    'event',
    'categorySelectorWrapper',
    'pageOverlay',
    'filterButton',
    'selectedCategory',
  ]

  initialize() {
    if (this.hasSelectedCategoryTarget) {
      $(this.filterButtonTarget).text($(this.selectedCategoryTarget).text())
    }
  }

  selectCategory(event) {
    const $this = this

    $.ajax({
      type: 'POST',
      url: $this.data.get('url'),
      data: {
        category: $(event.currentTarget).data('category'),
        scope: $this.data.get('scope'),
      },
      beforeSend: function () {
        $($this.filterButtonTarget).text($(event.currentTarget).text())
        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname.replace(/[0-9]/g, '').replace('/page/', '') +
          '?category=' +
          $(event.currentTarget).data('category')
        window.history.pushState({ path: newurl }, '', newurl)
      },
      success: function () {
        const lazyLoadOptions = { elements_selector: '.lazy' }
        const pageLazyLoad = new LazyLoad(lazyLoadOptions)
        pageLazyLoad.update()
      },
      error: function (err) { },
    })

    this.closeDropdown()
  }

  openDropdown() {
    if ($(this.categorySelectorWrapperTarget).hasClass('hidden')) {
      $(this.categorySelectorWrapperTarget).removeClass('hidden')
      $(this.pageOverlayTarget).removeClass('hidden')
      $('.filter-arrow').toggleClass('flip')
    } else {
      this.closeDropdown()
    }
  }

  closeDropdown() {
    $(this.categorySelectorWrapperTarget).addClass('hidden')
    $(this.pageOverlayTarget).addClass('hidden')

    $('.filter-arrow').toggleClass('flip')
  }
}

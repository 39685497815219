import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['career', 'categorySelectorWrapper']

  initialize() {
    let $this = this
    let category_arr = []

    $(this.careerTargets).each(function (idx, val) {
      category_arr = category_arr.concat($(val).data('category'))
    })

    // get unique values
    category_arr = category_arr.filter((item, i, ar) => ar.indexOf(item) === i)
    category_arr = category_arr.filter((item) => item)

    // prevent item duplication whenever you go back/forward in the browser
    $(this.categorySelectorWrapperTarget).empty()

    $(this.categorySelectorWrapperTarget).append(
      '<div class="category-all cursor-pointer text-xl md:text-2xl font-semibold lg:mr-10 mb-4 lg:mb-0 text-gray-500" data-action="click->core--careers-filter#selectCategoryAll">All</div>'
    )

    // append to dom
    category_arr.forEach(function (val) {
      $($this.categorySelectorWrapperTarget).append(
        `<div class="cursor-pointer text-xl md:text-2xl font-semibold lg:mr-10 mb-4 lg:mb-0 text-gray-500" data-action="click->core--careers-filter#selectCategory" data-category=${val}>${
          val.charAt(0).toUpperCase() + val.slice(1)
        }</div>`
      )
    })

    this.selectCategoryAll()
  }

  selectCategory(event) {
    const current_category = $(event.currentTarget)
    const current_category_name = $(event.currentTarget).data('category')
    $(this.careerTargets).each(function (idx, val) {
      if ($(val).data('category').includes(current_category_name)) {
        $(val).removeClass('hidden')
      } else {
        $(val).addClass('hidden')
      }
    })

    this.determineLinesVisibility()

    current_category
      .siblings()
      .removeClass('border-b-3 border-green-500 text-green-500')
      .addClass('text-gray-500')
    current_category
      .addClass('border-b-3 border-green-500 text-green-500')
      .removeClass('text-gray-500')
  }

  selectCategoryAll() {
    const current_category = $('.category-all')

    $(this.careerTargets).each(function (idx, val) {
      $(val).removeClass('border-b-3 border-green-500 text-green-500')
      $(val).removeClass('hidden')
    })

    current_category
      .siblings()
      .removeClass('border-b-3 border-green-500 text-green-500')
      .addClass('text-gray-500')
    current_category
      .addClass('border-b-3 border-green-500 text-green-500')
      .removeClass('text-gray-500')

    this.determineLinesVisibility()
  }

  determineLinesVisibility() {
    // if there is only one job posting after filter, remove the line
    if ($('.careers-list-wrapper').children().not('.hidden').length <= 1) {
      $('.job-line').addClass('hidden')
    } else {
      $('.job-line').removeClass('hidden')
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectButton', 'form']

  initialize() {
    var self = this

    $(document).keydown(function (e) {
      if (e.keyCode === 27) {
        e.preventDefault()
        self.resetVideoPlayer()
        $('.overlay-video-wrapper').children().removeClass('active')
      }
    })
  }

  openVideoPlayer(event) {
    const id = $(event.currentTarget).data('video-id')
    var iframe = $(
      `#overlay-video-${id} [data-oembed-type=\"video\"] iframe:visible`
    )

    if (iframe.length) {
      var video = iframe.attr('src')
      iframe.attr('src', video + '&autoplay=1')
    }
    $(`#overlay-video-${id}`).addClass('active')
  }

  closeVideoPlayer(event) {
    if (!$(event.target).hasClass('overlay')) return

    event.preventDefault()
    $(event.currentTarget).removeClass('active')
    this.resetVideoPlayer()
  }

  resetVideoPlayer() {
    $('.overlay-video-wrapper')
      .find('.overlay')
      .each(function (index, value) {
        const id = $(value).data('video-id')
        var iframe = $(
          `#overlay-video-${id} [data-oembed-type=\"video\"] iframe:visible`
        )
        if (iframe.length) {
          var video = iframe.attr('src')
          iframe.attr('src', video.replace('&autoplay=1', ''))
        }
      })
  }
}

import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['slider']

  connect() {
    this.slider = $(this.sliderTarget).slick({
      fade: true,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000
    })

    this.slider.on('click', function() { //go to next slide on click
      this.slider.slick('slickNext');
    }.bind(this))
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'participantInput',
    'slider',
    'modal',
    'selfManagedStandard',
    'selfManagedAP',
    'remoteManagedStandard',
    'remoteManagedAP'
  ]
  static values = { singleFacilitatorCharge: Boolean }

  connect() {
    this.sliderUpdate();
  }

  sliderUpdate() {
    this.participantInputTarget.value = this.sliderTarget.value;
    this.calculate();
  }

  textUpdate(event) {
    let input = event.currentTarget
    let value = Number(input.value)

    if (value && input.min && value < input.min) {
      input.value = input.min
    } else if (value && input.max && value > input.max) {
      input.value = input.max
    }

    this.sliderTarget.value = input.value;
    this.calculate();
  }

  calculate() {
    if (this.shouldShowModal) {
      this.modalController.openModal();
      this.participantInputTarget.value = Number(this.participantInputTarget.value) - 1;
    }

    const pricePerParticipant = Number(document.getElementById("price_per_participant").textContent)
    const pricePerFacilitator = Number(document.getElementById("price_per_facilitator").textContent)
    const facilitatorSegmentSize = Number(document.getElementById("facilitator_segment_size").textContent)
    const currency = document.getElementById("currency").innerText

    const participantInput = Number(this.participantInputTarget.value);
    const requiredFreelancers = Math.ceil(participantInput / facilitatorSegmentSize)
    const facilitatorPrice = this.singleFacilitatorChargeValue ? pricePerFacilitator : (pricePerFacilitator * requiredFreelancers)

    let participantInputAP = 0
    if (participantInput < 51) {
      participantInputAP = 0
    } else {
      participantInputAP = participantInput - 50
    }


    const selfManagedStandardCalculation = participantInput * pricePerParticipant
    if (this.hasSelfManagedStandardTarget) {
      this.selfManagedStandardTarget.textContent = this.displayAmount(selfManagedStandardCalculation, currency);
    }

    const selfManagedAPCalculation = participantInputAP * pricePerParticipant
    if (this.hasSelfManagedAPTarget) {
      this.selfManagedAPTarget.textContent = this.displayAmount(selfManagedAPCalculation, currency);
    }

    const remoteManagedStandardCalculation = (participantInput * pricePerParticipant) + facilitatorPrice
    if (this.hasRemoteManagedStandardTarget) {
      this.remoteManagedStandardTarget.textContent = this.displayAmount(remoteManagedStandardCalculation, currency)
    }

    const remoteManagedAPCalculation = (participantInputAP * pricePerParticipant) + facilitatorPrice
    if (this.hasRemoteManagedAPTarget) {
      this.remoteManagedAPTarget.textContent = this.displayAmount(remoteManagedAPCalculation, currency);
    }
  }

  enquire(e) {
    this.modalController.close();
    setTimeout(() => {
      $('.page-form-scroll')[0].scrollIntoView();
    }, 1);

    e.preventDefault();
    return false;
  }

  displayAmount(number, currency) {
    if (number === 0){
      return `Free`
    } else {
      if (currency === "GBP") {
        return `£${number} (excl. VAT)`;
      } else {
        return `$${number}`;
      }
    }
  }

  get shouldShowModal() {
    let input = this.participantInputTarget;
    let value = Number(input.value);
    return (input.max && value && input.max == value);
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.modalTarget, "modal")
  }
}

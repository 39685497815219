import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = ['blog', 'categorySelectorWrapper', 'selectedCategory']

  connect() {
    if (window.location.pathname.indexOf('/page/') > -1) {
      const yPos = $('#category_filter_chooser').position().top - 220
      setTimeout(() => window.scrollTo(0, yPos), 100)
    }
  }

  initialize() {
    this.selectedClassList = [
      'border-b-3',
      'border-t-3',
      'border-x-3',
      'md:border-t-0',
      'md:border-x-0',
      'px-2',
      'md:px-2',
      'border-green-700',
      'text-green-700',
      'rounded-md',
      'md:rounded-none',
    ].join(' ')
    this.unselectedClassList = 'text-gray-500'

    if (this.hasSelectedCategoryTarget) {
      const selected_key = $(this.selectedCategoryTarget).text()
      $(this.categorySelectorWrapperTarget)
        .children()
        .each((index, value) => {
          if ($(value).text() == selected_key) {
            $(value)
              .siblings()
              .removeClass(this.selectedClassList)
              .addClass(this.unselectedClassList)
            $(value)
              .addClass(this.selectedClassList)
              .removeClass(this.unselectedClassList)
          }
        })
    } else {
      this.selectCategoryAll()
    }
  }

  selectCategory(event) {
    const current_category = $(event.currentTarget)

    var $this = this
    $.ajax({
      type: 'POST',
      url: this.data.get('url'),
      data: {
        category: $(event.currentTarget).data('category'),
        scope: $this.data.get('scope'),
      },
      beforeSend: () => {
        current_category
          .siblings()
          .removeClass(this.selectedClassList)
          .addClass(this.unselectedClassList)
        current_category
          .addClass(this.selectedClassList)
          .removeClass(this.unselectedClassList)

        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname.replace(/[0-9]/g, '').replace('/page/', '') +
          '?category=' +
          $(event.currentTarget).data('category')
        window.history.pushState({ path: newurl }, '', newurl)
      },
      success: function () {
        const lazyLoadOptions = { elements_selector: '.lazy' }
        const pageLazyLoad = new LazyLoad(lazyLoadOptions)
        pageLazyLoad.update()
      },
      error: function (err) { },
    })
  }

  selectCategoryAll() {
    const current_category = $('.category-all')

    current_category
      .siblings()
      .removeClass(this.selectedClassList)
      .addClass(this.unselectedClassList)
    current_category
      .addClass(this.selectedClassList)
      .removeClass(this.unselectedClassList)
  }
}

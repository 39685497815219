import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['slider']

  connect() {
    this.slider = $(this.sliderTarget).slick({
      dots: true,
      arrows: false,
      autoplay: false,
      autoplay: false,
      autoplaySpeed: 3000,
    })
  }
}
